import history from "../../helper/browserHistory";
import {getArticlesAtPage} from "../../pages/blog/BlogUtil";

export {
    filterBlogs,
    getNewPage,
    onKeyDown,
    setQueryParams
}

function filterBlogs(filter, i18n) {
    if (typeof filter === "undefined")
        return;
    history.push("/" + i18n.language + "/blog/?page=0" + (filter.length > 0 ? "&search=" + filter : ""));
}

function onKeyDown(event, filterString, i18n) {
    if (event.key === 'Enter') {
        event.preventDefault();
        event.stopPropagation();
        filterBlogs(filterString, i18n);
    }
}

function setQueryParams(setPage, setFilterString, setAllArticles, setHasMore, page, filterString, location) {
    let searchParams = new URLSearchParams(location.search);
    const pageQuery = searchParams.get('page');
    if (pageQuery != null) {
        setPage(pageQuery);
    } else {
        setPage(0);
    }
    const searchQuery = searchParams.get('search');
    if (searchQuery != null) {
        setFilterString(searchQuery);
    } else {
        setFilterString("");
    }
    getArticlesAtPage(page, filterString).then(res => {
        setHasMore(res.hasMore);
        setPage(res.page);
        setAllArticles(res.articles);
        setFilterString(res.search);
    });
}

function getNewPage(type, filterString, page, language, setPage) {
    switch (type) {
        case 'next':
            history.push("/" + language + "/blog/?page=" + (filterString !== undefined ? (parseInt(page) + 1) + "&search=" + filterString : parseInt(page) + 1));
            setPage(parseInt(page) + 1);
            break;
        case 'back':
            history.push("/" + language + "/blog/" + (filterString !== undefined ? page === 0 ? "" : ("?page=" + (parseInt(page) - 1)) + "&search=" + filterString : page === 0 ? "" : ("?page=" + (parseInt(page) - 1))));
            setPage(parseInt(page) - 1);
            break;
        default:
            break;
    }
}