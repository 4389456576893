import React from 'react';
import {withTranslation} from "react-i18next";
import {loadTextsByNamespace} from "../../../../server/helper/ssrHelper";
import config from "../../../config/main.config";
import BreadCrumbs from '../../../components/breadcrumbs';
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid';
import EditHeaderComponent from '../../../components/editHeader';
import EditHTMLComponent from '../../../components/editHTML';
import Divider from 'semantic-ui-react/dist/commonjs/elements/Divider';
import BlogOverview from '../../../components/blogOverview';

const namespace = "BlogPage";
const BlogPage = () => {
    return (
        <Grid className='background-primary text-primary'>
            <Grid.Row centered={true}>
                <Grid.Column computer='12' tablet='12' mobile='14'>
                    <BreadCrumbs breadCrumbData={[{title: "blog", url: "/blog"}]}/>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row centered={true}>
                <Grid.Column computer='12' tablet='12' mobile='14'>
                    <EditHeaderComponent namespace={namespace} id='editHeader.blog.0' primary={true} type='h1'/>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row centered={true}>
                <Grid.Column computer='12' tablet='12' mobile='14'>
                    <EditHTMLComponent namespace={namespace} id='editHTML.blog.0'/>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row centered={true}>
                <Grid.Column computer='12' tablet='12' mobile='14'>
                    <Divider/>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row centered={true}>
                <Grid.Column computer='12' tablet='12' mobile='14'>
                    <BlogOverview/>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
}
BlogPage.fetchData = () => {
    return loadTextsByNamespace(config.BASE_URL, 'BlogPage').then((text) => {
        return text;
    })
}
export default withTranslation(['common'])(BlogPage);
