import React from "react";
import {withTranslation} from "react-i18next";

import {getAllArticles, loadTextsByNamespace} from "../../../server/helper/ssrHelper";
import config from "../../config/main.config";

import BlogPage from "../../userContent/userPages/BlogPage/BlogPage";

const Blog = () => {

    return (
        <BlogPage/>
    )
}

Blog.fetchData = (req) => {
    let page = 0;
    let sickeFusion = {};
    if (req.query.hasOwnProperty('page')) {
        page = req.query.page;
    }
    return loadTextsByNamespace(config.BASE_URL, 'Blog').then((text) => {
        return getAllArticles(page, config.BASE_URL).then(articles => {
            sickeFusion = text;
            sickeFusion = {...sickeFusion, articles};
            return sickeFusion;
        })
    })
}

export default withTranslation()(Blog)