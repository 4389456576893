import React, { useEffect, useState } from "react";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import Card from "semantic-ui-react/dist/commonjs/views/Card";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import * as utils from "./utils";
import { getAllArticlesAtPage } from "../../helper/cmsHelper";
import history from "../../helper/browserHistory";
import { withTranslation } from "react-i18next";
import BlogPostPreview from "../../pages/blog/BlogPostPreview";
import DropdownMenu from "../dropdownMenu/DropdownMenu/DropdownMenu";
import DropdownItem from "../dropdownMenu/DropdownItem";
import sharedConfig from "../../../shared/sharedConfig";

const BlogOverview = (props) => {
	const { i18n, store } = props;
	const [category, setCategory] = useState("all");
	const [allArticles, setAllArticles] = useState(getAllArticlesAtPage(store));
	const [page, setPage] = useState(0);
	const [hasMore, setHasMore] = useState(true);
	const [filterString, setFilterString] = useState("");

	useEffect(() => {
		if (typeof props.staticContext !== "undefined") {
			setAllArticles(props.staticContext.articles);
			setPage(props.staticContext.page);
			setHasMore(props.staticContext.hasMore);
		}
		if (typeof window !== "undefined") {
			utils.setQueryParams(
				setPage,
				setFilterString,
				setAllArticles,
				setHasMore,
				page,
				filterString,
				window.location,
			);
		}
	}, []);

	useEffect(() => {
		utils.setQueryParams(
			setPage,
			setFilterString,
			setAllArticles,
			setHasMore,
			page,
			filterString,
			history.location,
		);
	}, [history.location]);
	return (
		<Grid>
			<Grid.Row style={{ justifyContent: "space-between" }}>
				<div>
					<DropdownMenu
						key={"edit-theme-7"}
						className={"admin-button-basic"}
						name={i18n.t("blog:blog.category_search")}
						selector={true}
						onChange={(e) => {
							setCategory(e);
						}}
					>
						{sharedConfig.categories.map((el) => (
							<DropdownItem key={el} value={el}>
								{i18n.t("blog:blog." + el)}
							</DropdownItem>
						))}
					</DropdownMenu>
				</div>
				<div>
					<label>{i18n.t("blog:blog.article_search")}</label>
					<input
						type="text"
						className={"blogSearch"}
						value={filterString !== undefined ? filterString : ""}
						placeholder={i18n.t("blog:blog.search")}
						onKeyDown={(e) => utils.onKeyDown(e, filterString, i18n)}
						onChange={(e) => {
							setFilterString(e.target.value);
						}}
					/>
				</div>
			</Grid.Row>
			<Grid.Row>
				<Grid.Column>
					<Card.Group>
						{allArticles
							.filter((article) =>
								category === "all" ? true : article.category === category,
							)
							.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
							.map((article, index) => (
								<BlogPostPreview
									key={article.id + "_preview"}
									article={article}
									index={index}
								/>
							))}
					</Card.Group>
				</Grid.Column>
			</Grid.Row>
			<Grid.Row>
				<Grid.Column>
					{page !== "0" && page !== 0 ? (
						<Button
							className={"paginationButton"}
							onClick={(_) =>
								utils.getNewPage(
									"back",
									filterString,
									page,
									i18n.language,
									setPage,
								)
							}
						>
							{i18n.t("common:common.back")}
						</Button>
					) : null}

					{hasMore ? (
						<Button
							className={"paginationButton"}
							onClick={(_) =>
								utils.getNewPage(
									"next",
									filterString,
									page,
									i18n.language,
									setPage,
								)
							}
						>
							{i18n.t("common:common.next")}
						</Button>
					) : null}
				</Grid.Column>
			</Grid.Row>
		</Grid>
	);
};

export default withTranslation(["blog", "common"])(BlogOverview);
