import React from "react";
import { withTranslation } from "react-i18next";
import Card from "semantic-ui-react/dist/commonjs/views/Card";
import config from "../../config/main.config";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import history from "../../helper/browserHistory";
import moment from "moment";
import sharedConfig from "../../../shared/sharedConfig";

const BlogPostPreview = (props) => {
	const { article, i18n, index, as } = props;

	if (as === "Compressed") {
		return (
			<div
				className={"blog-preview-compressed"}
				onClick={(_) => {
					history.push(
						"/" + i18n.language + "/blog-article/" + article.blogLink,
					);
				}}
				key={`blog-${article.id}`}
				itemScope
				itemType="https://schema.org/Article"
				style={{ cursor: "pointer" }}
			>
				{article.thumbnail && (
					<picture>
						<source
							media={"(min-width: 1024px)"}
							srcSet={
								config.BASE_URL_IMAGES +
								"desktop/" +
								(/\.(gif|jpe?g|tiff?|png|webp|bmp)$/i.test(article.thumbnail)
									? article.thumbnail.substring(
											0,
											article.thumbnail.lastIndexOf("."),
										)
									: article.thumbnail) +
								`.webp ${sharedConfig.IMAGE_SIZES.LARGE}w` +
								"," +
								config.BASE_URL_IMAGES +
								"desktop/" +
								article.thumbnail
							}
						/>
						<source
							media={"(min-width: 321px)"}
							srcSet={
								config.BASE_URL_IMAGES +
								"tablet/" +
								(/\.(gif|jpe?g|tiff?|png|webp|bmp)$/i.test(article.thumbnail)
									? article.thumbnail.substring(
											0,
											article.thumbnail.lastIndexOf("."),
										)
									: article.thumbnail) +
								`.webp ${sharedConfig.IMAGE_SIZES.LARGE}w` +
								"," +
								config.BASE_URL_IMAGES +
								"tablet/" +
								article.thumbnail
							}
						/>
						<source
							media={"(max-width: 320px)"}
							srcSet={
								config.BASE_URL_IMAGES +
								"mobile/" +
								(/\.(gif|jpe?g|tiff?|png|webp|bmp)$/i.test(article.thumbnail)
									? article.thumbnail.substring(
											0,
											article.thumbnail.lastIndexOf("."),
										)
									: article.thumbnail) +
								`.webp ${sharedConfig.IMAGE_SIZES.LARGE}w` +
								"," +
								config.BASE_URL_IMAGES +
								"mobile/" +
								article.thumbnail
							}
						/>
						<img
							className={"blog-preview-image"}
							itemProp={"image"}
							src={config.BASE_URL_IMAGES + "all-images/" + article.thumbnail}
							alt={article.header}
						/>
					</picture>
				)}
				<span className={"blog-preview-title"}>
					{article.header.length > 20
						? article.header.substring(0, 20) + "..."
						: article.header}
				</span>
			</div>
		);
	} else {
		return (
			<Card
				key={`blog-${article.id}`}
				href={"/" + i18n.language + "/blog-article/" + article.blogLink}
				className={"blogOverviewCard"}
				itemScope
				itemType="https://schema.org/Article"
			>
				<Card.Content>
					{article.thumbnail && (
						<picture>
							<source
								media={"(min-width: 1024px)"}
								srcSet={
									config.BASE_URL_IMAGES +
									"desktop/" +
									(/\.(gif|jpe?g|tiff?|png|webp|bmp)$/i.test(article.thumbnail)
										? article.thumbnail.substring(
												0,
												article.thumbnail.lastIndexOf("."),
											)
										: article.thumbnail) +
									`.webp ${sharedConfig.IMAGE_SIZES.LARGE}w` +
									"," +
									config.BASE_URL_IMAGES +
									"desktop/" +
									article.thumbnail
								}
							/>
							<source
								media={"(min-width: 321px)"}
								srcSet={
									config.BASE_URL_IMAGES +
									"tablet/" +
									(/\.(gif|jpe?g|tiff?|png|webp|bmp)$/i.test(article.thumbnail)
										? article.thumbnail.substring(
												0,
												article.thumbnail.lastIndexOf("."),
											)
										: article.thumbnail) +
									`.webp ${sharedConfig.IMAGE_SIZES.LARGE}w` +
									"," +
									config.BASE_URL_IMAGES +
									"tablet/" +
									article.thumbnail
								}
							/>
							<source
								media={"(max-width: 320px)"}
								srcSet={
									config.BASE_URL_IMAGES +
									"mobile/" +
									(/\.(gif|jpe?g|tiff?|png|webp|bmp)$/i.test(article.thumbnail)
										? article.thumbnail.substring(
												0,
												article.thumbnail.lastIndexOf("."),
											)
										: article.thumbnail) +
									`.webp ${sharedConfig.IMAGE_SIZES.LARGE}w` +
									"," +
									config.BASE_URL_IMAGES +
									"mobile/" +
									article.thumbnail
								}
							/>
							<img
								className={"previewImage"}
								itemProp={"image"}
								src={config.BASE_URL_IMAGES + "all-images/" + article.thumbnail}
								alt={article.header}
							/>
						</picture>
					)}
					<Card.Meta className={"cardDate"}>
						{moment(article.date).format("DD.MM.YYYY")}
					</Card.Meta>
					<Card.Header itemProp={"headline"} className={"cardHeader"}>
						{article.header}
					</Card.Header>
					<Card.Meta>
						<span className={"cardAuthor"} itemProp={"author"}>
							{article.authorName}
						</span>
					</Card.Meta>
					<Card.Description itemProp={"text"} className={"cardText"}>
						{article.description}
						<br />
					</Card.Description>
					<Button className={"cardButton"}>{i18n.t("blog:blog.read")}</Button>
				</Card.Content>
			</Card>
		);
	}
};

export default withTranslation()(BlogPostPreview);
